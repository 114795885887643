@font-face {
    font-family: 'Metropolis';
    src: local('Metropolis'), url('./fonts/Metropolis/Metropolis-Regular.woff2') format('woff');
}

@font-face {
    font-family: 'Metropolis';
    font-weight: bold;
    src: local('Metropolis-Bold'), url('./fonts/Metropolis/Metropolis-Bold.woff2') format('woff');
}

@font-face {
    font-family: 'fineliner_scriptregular';
    src: url('./fonts/FinelinerScript/finelinerscript-webfont.eot');
    src: url('./fonts/FinelinerScript/finelinerscript-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/FinelinerScript/finelinerscript-webfont.woff') format('woff'),
    url('./fonts/FinelinerScript/finelinerscript-webfont.ttf') format('truetype'),
    url('./fonts/FinelinerScript/finelinerscript-webfont.svg#fineliner_scriptregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

body {
    margin: 0;
    font-family: Metropolis, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
